<template lang="pug">
  .page.in-development-page
    h1 Управление кэшем
    el-button(@click="cacheClear()") Очистить кэш
    el-button(@click="permissionsCreate()") Создать разрешения для новых страниц

</template>

<script>
  import { notifyRequest } from '@/utils/api'
  export default {
    name: 'CacheControl',
    components: {},
    data() {
      return {
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      },
    },
    methods: {
      async cacheClear() {
        await notifyRequest({
          url: 'actions/cache-clear',
          method: 'post'
        }, 'Кэш успешно очищен', 'Успешно!')
      },
      async permissionsCreate() {
        await notifyRequest({
          url: 'actions/permissions-create',
          method: 'post'
        }, 'Разрешения успешно созданы', 'Успешно!')
      },
    }
  }
</script>
<style rel="stylesheet/scss" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .in-development-page
    padding-left: 30px
</style>
